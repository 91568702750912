@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/* ---------------- @FONT-FACE ----------------------- */
@font-face {
    font-family: EuclidCircularA-Regular;
    src: url("../fonts/bodyfont/EuclidCircular A Regular.ttf");
}
@font-face {
    font-family: EuclidCircularB-Medium;
    src: url("../fonts/EuclidCircularB-Medium.otf");
}

@font-face {
    font-family: EuclidCircularA-SemiBold;
    src: url("../fonts/bodyfont/Euclid Circular A SemiBold.ttf");
}

@font-face {
    font-family: RobotoSlab-Regular;
    src: url("../fonts/RobotoSlab-Regular.ttf");
}

@font-face {
    font-family: RobotoSlab-Bold;
    src: url("../fonts/RobotoSlab-Bold.ttf");
}

@font-face {
    font-family: Gelo-Bold;
    src: url("../fonts/WebPV_Gelo_Bd/WebStaticFonts/Gelo_W_Bd.woff");
}

@font-face {
    font-family: Gelo-Regular;
    src: url("../fonts/WebPV_Gelo_Rg/WebStaticFonts/Gelo_W_Rg.woff");
}

/* ---------------- @FONT-FACE ----------------------- */

body {
    font-family: "EuclidCircularA-Regular", sans-serif;
    color: #857c7a;
    background-color: #fff;
    font-size: 15px;
    font-weight: 400;
}

b {
    font-family: "EuclidCircularA-SemiBold", sans-serif;
}

img {
    max-width: 100%;
}

a {
    color: #f12a02;
    text-decoration: none;
}

a:hover {
    color: #3291e6;
    text-decoration: none;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

#header {
    transition: all 0.5s;
    z-index: 997;
    background-color: #fff;
    padding: 20px 0px;
    border-bottom: 2px solid #e0dede;
}

.header-top {
    background-color: #f2f1f1;
    padding: 10px 0px;
    color: #857c7a;
    font-size: 15px;
}

.logo-container {
    /*display: table;*/
    display: initial;
}

.acadia-logo {
    border-right: 1px solid #d8d8d8;
    display: table-cell;
    margin: 0;
}
.nuplazid {
    display: table-cell;
    margin: 0;
}

.navbar {
    padding: 0;
    float: right;
    right: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar > ul > li {
    position: relative;
    white-space: nowrap;
    padding: 15px 0 8px 20px;
}

.navbar > ul > li img {
    padding-right: 5px;
    width: 50%;
}

.navbar a,
.navbar a:focus {
    justify-content: space-between;
    font-size: 17px;
    color: #857c7a;
    font-weight: 600;
    white-space: nowrap;
    transition: 0.3s;
    padding: 35px 2px;
    text-transform: uppercase;
    font-family: "EuclidCircularA-SemiBold", sans-serif;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    color: #4c9fb7;
    border-color: none;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    right: 20px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li {
    min-width: 150px;
}

.navbar .dropdown ul li img {
    padding: 5px;
    width: 30%;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #082744;
    border: none;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
    color: #1977cc;
}

.navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
        left: -100%;
    }
}

.mobile-nav-toggle {
    color: #2c4964;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }
    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(28, 47, 65, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile > ul > li {
    padding: 0;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #2c4964;
    border: none;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
    color: #1977cc;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
    color: #1977cc;
}

.navbar-mobile .dropdown > .dropdown-active {
    display: block;
}

.bannerbg {
    background: url(../../assets/img/banner-bg.png) no-repeat center;
    background-size: cover;
    position: relative;
    min-height: 480px;
    padding: 30px 0px;
}
.bannerbg p {
    color: #fff;
    font-size: 19px;
    margin-bottom: 1.5rem;
}
.bannerbg h2 {
    color: #f8e061;
    font-family: "Gelo-Regular", "RobotoSlab-Regular", sans-serif;
    font-size: 40px;
    margin-top: 20px;
}
.bannerbg h1 {
    color: #fff;
    font-family: "Gelo-Regular", "RobotoSlab-Regular", sans-serif;
    font-size: 80px;
    line-height: 70px;
}
.trademark-r {
    font-size: 50%;
    vertical-align: 1em;
    line-height: 0;
    margin-left: -15px;
}
.tagline-underline {
    text-decoration-line: underline;
    text-decoration-color: #f8e061;
    text-underline-offset: 0.12em;
    text-decoration-thickness: 0.07em;
}

.cardDesign {
    background-color: #fff;
    border-radius: 10px;
    /*	padding: 20px;*/
    margin-top: 70px;
    /*	margin-right: 50px;*/
    background: #fff url(../../assets/img/cardpreview.png) no-repeat;
    background-size: cover;
    position: relative;
    height: 103%;
}
.cardDesign hr {
    height: 3px;
    background-color: #5d5d5d;
    margin: 15px 0px;
    opacity: 1;
}
.cardBody {
    padding: 5px 15px;
}
.cardBody p {
    color: #767676;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.cardFooter {
    font-size: 12px;
}
.cardFooter h1 {
    color: #767676;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    margin: 0px;
    font-family: "Open Sans", sans-serif;
}
.cardFooter p {
    color: #767676;
    font-size: 11px;
    margin-bottom: 0px;
}

.form-bg {
    transition: all 500ms ease-out;
    margin: 3% 0px;
}

.form-bg h1 {
    font-size: 16px !important;
    color: #a79c9a !important;
    font-family: "EuclidCircularA-Regular", sans-serif !important;
    margin-bottom: 0px !important;
}

.form-bg form a {
    color: #66b7c7;
    font-size: 14px;
}

.form-bg form a:hover {
    text-decoration: underline;
}

.form-bg a {
    color: #1fd0f9;
    font-size: 14px;
}

.form-bg a:hover {
    text-decoration: underline;
}

.form-bg form {
    margin: 5px 0;
}

.form-bg form .eye {
    cursor: pointer;
    float: right;
    margin-top: -32px;
    margin-right: 20px;
    color: #1fd0f9;
}

.form-bg form i:hover {
    color: #001f60;
}

.form-bg form .form-group {
    margin-bottom: 10px;
}

.form-bg form label {
    font-size: 14px;
    color: #a79c9a;
    font-weight: 400;
}
.form-bg form label span {
    color: #ff0000 !important;
}
.form-bg form label span.marked-required {
    color: #ff0000 !important;
}

.form-bg form input {
    background-color: #f6f6f6;
    border: none;
    height: 50px;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    color: #a79c9a;
}

.form-bg form select {
    background-color: #f6f6f6;
    border: none;
    height: 50px;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    color: #a79c9a;
    display: block;
    width: 100%;
    padding: 0.375rem 1rem 0.375rem 0.75rem;
}

.error-input {
    border: 1px solid #ff0000 !important;
}
.form-bg form small {
    font-size: 11px;
    line-height: 12px;
}

.form-bg form .form-checkbox {
    width: 1.7em;
    height: 1.7em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #1fd0f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    border-radius: 0;
}

.form-bg form .form-checkbox:checked {
    background-color: #1fd0f9;
    border-color: #1fd0f9;
    color: #fff;
}

.form-bg form input:focus {
    border: 1px solid #4c9fb7;
    background-color: #f6f6f6;
    box-shadow: none;
}

.form-bg form button {
    outline: none;
    margin: 30px 10px 10px 10px;
}

.details {
    margin: 3% 0;
}
.details h1 {
    color: #66b7c7;
    margin-bottom: 20px;
    font-family: "Gelo-Regular", "EuclidCircularB-Medium";
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -0.4px;
}
.details h1 span {
    color: #f8e061;
}
.details h3 {
    color: #66b7c7;
    font-size: 16px;
    font-weight: 400;
}
.module {
    margin: 80px;
    border-radius: 15px;
    background: #66b7c7 url(../../assets/img/background-hearts-desktop.png) no-repeat center;
    background-size: cover;
    min-height: 200px;
    padding: 50px 40px;
}
.module .icon {
    text-align: center;
    padding: 5px;
    border-radius: 50%;
    border: 5px solid #f8e061;
    background-color: #fff;
    width: 100px;
    height: 100px;
    margin: 0 auto 10px auto;
    margin-top: -80px;
}
.module h1,
.module2 h1 {
    color: #fff;
    font-family: "Gelo-Regular", "RobotoSlab-Bold", sans-serif;
    font-size: 35px;
    margin-bottom: 5px;
    letter-spacing: 1px;
}
.module h2,
.module2 h2 {
    color: #fff;
    font-family: "EuclidCircularA-Regular", sans-serif;
    font-size: 22px;
    line-height: 30px;
}
.module2 {
    margin: 80px;
    border-radius: 15px;
    background: #66b7c7 url(../../assets/img/how-we-work-background1.png) no-repeat center right;
    background-size: contain;
    min-height: 200px;
    padding: 50px 40px;
}
#sticky_isi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 184px;
    transition: height 0.4s linear;
    box-shadow: 0 -1px 9px 0 rgb(0 0 0 / 40%);
    z-index: 1000;
    overflow: hidden;
    box-sizing: border-box;
}
.brand-container {
    width: 50%;
    margin: 0 auto;
}

.welcomenote {
    padding: 0px 100px;
    color: #a79c9a;
    line-height: 30px;
}
.welcomemessage {
    color: #a79c9a;
}

.btn {
    padding: 10px 100px;
    text-align: center;
    background-color: #fff;
    border-radius: 30px;
    margin-top: 10px;
    color: #66b7c7;
    font-size: 20px;
    font-family: "EuclidCircularA-SemiBold", sans-serif;
    border: 2px solid #fff;
    transition: all 0.5s;
}
.btn:hover {
    color: #fff;
    border: 2px solid #fff;
    background-color: transparent;
}
.btn i {
    padding-left: 10px;
}

.button {
    text-align: center;
    border-radius: 30px;
    margin: 10px 20px 0px 0px;
    font-family: "EuclidCircularA-SemiBold", sans-serif;
    font-size: 20px;
    outline: none;
    padding: 16px 30px 14px;
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.82px;
    transition: all 0.5s;
}
.btn-primary {
    background-color: #fff;
    border: 2px solid #66b7c7;
    color: #66b7c7;
}
.btn-primary:hover {
    background-color: #6cacc0;
    color: #fff;
    border: 2px solid #6cacc0;
    text-decoration: none;
    color: #fff;
}

.btn-secondary {
    background-color: #6cacc0;
    color: #fff;
    border: 2px solid #6cacc0;
}
.btn-secondary:hover {
    background-color: #fff;
    border: 2px solid #66b7c7;
    color: #66b7c7;
    text-decoration: none;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
    background-color: #6cacc0;
    color: #fff;
    border: 2px solid #6cacc0;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
    background-color: #fff;
    border: 2px solid #66b7c7;
    color: #66b7c7;
}

.suc_bg {
    text-align: center;
    background-color: #00abc8;
    padding: 30px 30px 20px 30px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.err-bg {
    background-color: #e02020;
    padding: 30px 30px 20px 30px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
}
.err-bg i,
.suc_bg i {
    color: #fff;
    font-size: 90px;
    margin-bottom: 20px;
}
.err-bg h1,
.suc_bg h1 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}
.err-bg h2,
.suc_bg h2 {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
}
.err-bg p,
.suc_bg p {
    color: #fff;
    margin-bottom: 5px;
    font-size: 13px;
}
.modal-content {
    border-radius: 10px;
}
.modal-footer {
    border-top: 0px solid #dee2e6;
    display: block;
}
.modal-footer .button {
    margin-top: 0px;
}

.card-container {
    width: 80%;
    margin: 3% auto;
}

.eligibility {
    padding: 30px;
}
.eligibility p {
    color: #a79c9a;
}
.eligibility .listoption {
    margin: 15px 0px;
}
.eligibility .form-check .form-check-input {
    width: 27px;
    height: 27px;
    border: 1px solid #ddd;
}
.eligibility .form-check label {
    line-height: 35px;
    padding-left: 10px;
}

.eligibility .form-check-input:checked {
    background-color: #66b7c7;
    border-color: #66b7c7;
    background-image: none;
}

.important {
    margin: 5% 0;
    background-color: #fff;
}
.headerimportant {
    background-color: #4c9fb7;
    padding: 8px 0px;
    color: #fff;
    font-size: 20px;
}
.important-content {
    padding: 30px 0px 0px 0px;
    color: #857c7a;
    font-family: "EuclidCircularA-Regular", sans-serif;
}
.important-content .custom-teal {
    color: #429ca7;
}
.important-content h1 {
    color: #429ca7;
    font-size: 20px;
    font-family: "EuclidCircularB-Medium", sans-serif;
}
.important-content ul {
    margin: 0px;
    padding: 0px 0px 40px 20px;
}
.important-content ul li {
    margin: 15px 0px;
    list-style: none;
    color: #857c7a;
    font-weight: normal;
    font-size: 18px !important;
}
.important-content ul li:not(.nav-item):before {
    position: absolute;
    content: "\2022";
    color: #f8e162;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -0.7em;
    vertical-align: top;
    font-size: 30px;
    margin-top: -0.3em;
}
.important-content ul li b {
    color: #429ca7;
    font-family: "EuclidCircularB-Medium", sans-serif;
}
.important-content ul li ul {
    margin: 0px;
    padding: 0px 0px 0px 30px;
}
.important-content ul li ul li {
    margin: 15px 0px;
    list-style: none;
    color: #857c7a;
    font-weight: normal;
    font-size: 18px !important;
}
.important-content ul li ul li:not(.nav-item):before {
    position: absolute;
    content: "\2022";
    color: #f8e162;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -0.7em;
    vertical-align: top;
    font-size: 30px;
    margin-top: -0.3em;
}
.important-content .custom-bullet ul {
    list-style: none !important;
    }
.important-content .custom-bullet li {
    position: relative !important;
    }
.important-content .custom-bullet li:before {
    position: absolute !important;
    top: 15px;
    content: ' ' !important;
    display: block !important;
    width: 0.40em !important;
    height: 0.40em;
    border: 2px solid #f8e162;
    border-radius: 50%;
    }
.important-content .boxcontent {
    background-color: #f2fafc;
    padding: 20px;
    margin: 10px 0px 20px 0px;
}
.important-content p {
    font-size: 18px !important;
}
.important-content p a {
    color: #4c9fb7;
    /*	text-decoration: underline;*/
}
.important-content p a:hover {
    text-decoration: underline;
    text-decoration-color: #4c9fb7;
}
.important-content .boxcontent ul {
    margin: 0px;
    padding: 0px 0px 0px 40px;
}

.important-content .boxcontent ul li {
    margin: 5px 0px;
    list-style: none;
    color: #429ca7;
    font-size: 19px !important;
    font-weight: bold;
    font-family: "EuclidCircularB-Medium", sans-serif;
}

.important-content .boxcontent ul li:not(.nav-item):before {
    position: absolute;
    content: "\2022";
    color: #f8e162;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -0.7em;
    vertical-align: top;
    font-size: 30px;
    margin-top: -0.4em;
}

.backtotop {
    background-color: #4c9fb7;
    padding: 10px;
    color: #fff;
    text-align: center;
}
.backtotop p {
    color: #fff;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.backtop-icon {
    display: inline-block;
    width: 50px;
    height: 25px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background-color: #4c9fb7;
    color: #fff;
}
.backtop-icon a:hover {
    cursor: pointer;
}
.backtop-icon i {
    transform: rotate(1deg);
    -webkit-transform: rotate(1deg);
    vertical-align: bottom;
    color: #fff;
    display: inline-block;
    padding: 3px;
    font-size: 16px;
    line-height: 19px;
}

footer {
    color: #444;
    font-size: 14px;
    padding: 50px 0;
    background-color: #f2fafc;
}
footer p {
    color: #857c7a;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.22px;
}
footer .acadia {
    margin-bottom: 10px;
}

footer ul {
    padding: 0;
    margin: 0 0 30px 0;
    float: left;
    display: inline-block;
    width: 100%;
}
footer ul li {
    list-style: none;
    padding: 5px 20px;
    float: left;
}
footer ul li:first-child {
    padding-left: 0px;
}
footer ul li:after {
    border-right: 2px solid #857c7a;
}
footer ul li a {
    color: #4c9fb7;
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 1px;
    font-size: 18px;
    line-height: 23px;
    font-family: "EuclidCircularB-Medium";
}

footer ul li a:hover {
    color: #4c9fb7;
    text-decoration: underline !important;
    text-decoration-color: #f8e162 !important;
}

@media (max-width: 1120px) {
    .bannerbg h1 {
        font-size: 65px;
    }
    .cardDesign {
        margin-right: 0px;
    }
    .cardFooter h1 {
        font-size: 12px;
        line-height: 14px;
    }
    .module {
        margin: 30px 0px;
    }
    .brand-container {
        width: 50%;
        margin: 0 auto;
    }
    .module2 {
        margin: 30px 0px;
    }
}

@media (max-width: 768px) {
    .bannerbg h2 {
        margin-bottom: 0px;
    }
    .bannerbg h1 {
        font-size: 45px;
        line-height: 45px;
    }
    .btn {
        padding: 10px 50px;
    }
    .cardFooter h1 {
        font-size: 12px;
        line-height: 14px;
    }
    .trademark-r {
        margin-left: -5px;
    }
    .cardDesign {
        margin-right: 0px;
    }
    .module {
        margin: 30px 0px;
    }
    .brand-container {
        width: 100%;
    }
    .module2 {
        margin: 30px 0px;
    }
    .details h1 {
        font-size: 35px;
        line-height: 40px;
    }
}
