@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap";
@font-face {
  font-family: EuclidCircularA-Regular;
  src: url("EuclidCircular A Regular.c763758a.ttf");
}

@font-face {
  font-family: EuclidCircularB-Medium;
  src: url("EuclidCircularB-Medium.6c8b0d74.otf");
}

@font-face {
  font-family: EuclidCircularA-SemiBold;
  src: url("Euclid Circular A SemiBold.96402050.ttf");
}

@font-face {
  font-family: RobotoSlab-Regular;
  src: url("RobotoSlab-Regular.c9db96f5.ttf");
}

@font-face {
  font-family: RobotoSlab-Bold;
  src: url("RobotoSlab-Bold.bd5df5c3.ttf");
}

@font-face {
  font-family: Gelo-Bold;
  src: url("Gelo_W_Bd.2e663c71.woff");
}

@font-face {
  font-family: Gelo-Regular;
  src: url("Gelo_W_Rg.38847aca.woff");
}

body {
  color: #857c7a;
  background-color: #fff;
  font-family: EuclidCircularA-Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

b {
  font-family: EuclidCircularA-SemiBold, sans-serif;
}

img {
  max-width: 100%;
}

a {
  color: #f12a02;
  text-decoration: none;
}

a:hover {
  color: #3291e6;
  cursor: pointer;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

#header {
  z-index: 997;
  background-color: #fff;
  border-bottom: 2px solid #e0dede;
  padding: 20px 0;
  transition: all .5s;
}

.header-top {
  color: #857c7a;
  background-color: #f2f1f1;
  padding: 10px 0;
  font-size: 15px;
}

.logo-container {
  display: initial;
}

.acadia-logo {
  border-right: 1px solid #d8d8d8;
  margin: 0;
  display: table-cell;
}

.nuplazid {
  margin: 0;
  display: table-cell;
}

.navbar {
  float: right;
  padding: 0;
  right: 0;
}

.navbar ul {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.navbar li {
  position: relative;
}

.navbar > ul > li {
  white-space: nowrap;
  padding: 15px 0 8px 20px;
  position: relative;
}

.navbar > ul > li img {
  width: 50%;
  padding-right: 5px;
}

.navbar a, .navbar a:focus {
  color: #857c7a;
  white-space: nowrap;
  text-transform: uppercase;
  justify-content: space-between;
  padding: 35px 2px;
  font-family: EuclidCircularA-SemiBold, sans-serif;
  font-size: 17px;
  font-weight: 600;
  transition: all .3s;
}

.navbar a i, .navbar a:focus i {
  margin-left: 5px;
  font-size: 12px;
  line-height: 0;
}

.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #4c9fb7;
  border-color: none;
}

.navbar .dropdown ul {
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  margin: 0;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  right: 20px;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar .dropdown ul li {
  min-width: 150px;
}

.navbar .dropdown ul li img {
  width: 30%;
  padding: 5px;
}

.navbar .dropdown ul a {
  text-transform: none;
  color: #082744;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #1977cc;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.navbar .dropdown .dropdown ul {
  visibility: hidden;
  top: 0;
  left: calc(100% - 30px);
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 0;
  left: 100%;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.mobile-nav-toggle {
  color: #2c4964;
  cursor: pointer;
  font-size: 28px;
  line-height: 0;
  transition: all .5s;
  display: none;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  z-index: 999;
  background: #1c2f41e6;
  transition: all .3s;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  background-color: #fff;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 55px 15px 15px;
  overflow-y: auto;
}

.navbar-mobile > ul > li {
  padding: 0;
}

.navbar-mobile a, .navbar-mobile a:focus {
  color: #2c4964;
  border: none;
  padding: 10px 20px;
  font-size: 15px;
}

.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #1977cc;
}

.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  margin: 10px 20px;
  padding: 10px 0;
  display: none;
  position: static;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #1977cc;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.bannerbg {
  min-height: 480px;
  background: url("banner-bg.a5d78363.png") center / cover no-repeat;
  padding: 30px 0;
  position: relative;
}

.bannerbg p {
  color: #fff;
  margin-bottom: 1.5rem;
  font-size: 19px;
}

.bannerbg h2 {
  color: #f8e061;
  margin-top: 20px;
  font-family: Gelo-Regular, RobotoSlab-Regular, sans-serif;
  font-size: 40px;
}

.bannerbg h1 {
  color: #fff;
  font-family: Gelo-Regular, RobotoSlab-Regular, sans-serif;
  font-size: 80px;
  line-height: 70px;
}

.trademark-r {
  vertical-align: 1em;
  margin-left: -15px;
  font-size: 50%;
  line-height: 0;
}

.tagline-underline {
  text-underline-offset: .12em;
  text-decoration-line: underline;
  text-decoration-color: #f8e061;
  text-decoration-thickness: .07em;
}

.cardDesign {
  height: 103%;
  background: #fff url("cardpreview.d1641396.png") 0 0 / cover no-repeat;
  border-radius: 10px;
  margin-top: 70px;
  position: relative;
}

.cardDesign hr {
  height: 3px;
  opacity: 1;
  background-color: #5d5d5d;
  margin: 15px 0;
}

.cardBody {
  padding: 5px 15px;
}

.cardBody p {
  color: #767676;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
}

.cardFooter {
  font-size: 12px;
}

.cardFooter h1 {
  color: #767676;
  margin: 0;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

.cardFooter p {
  color: #767676;
  margin-bottom: 0;
  font-size: 11px;
}

.form-bg {
  margin: 3% 0;
  transition: all .5s ease-out;
}

.form-bg h1 {
  color: #a79c9a !important;
  margin-bottom: 0 !important;
  font-family: EuclidCircularA-Regular, sans-serif !important;
  font-size: 16px !important;
}

.form-bg form a {
  color: #66b7c7;
  font-size: 14px;
}

.form-bg form a:hover {
  text-decoration: underline;
}

.form-bg a {
  color: #1fd0f9;
  font-size: 14px;
}

.form-bg a:hover {
  text-decoration: underline;
}

.form-bg form {
  margin: 5px 0;
}

.form-bg form .eye {
  cursor: pointer;
  float: right;
  color: #1fd0f9;
  margin-top: -32px;
  margin-right: 20px;
}

.form-bg form i:hover {
  color: #001f60;
}

.form-bg form .form-group {
  margin-bottom: 10px;
}

.form-bg form label {
  color: #a79c9a;
  font-size: 14px;
  font-weight: 400;
}

.form-bg form label span, .form-bg form label span.marked-required {
  color: red !important;
}

.form-bg form input {
  height: 50px;
  color: #a79c9a;
  background-color: #f6f6f6;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
}

.form-bg form select {
  height: 50px;
  color: #a79c9a;
  width: 100%;
  background-color: #f6f6f6;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: .375rem 1rem .375rem .75rem;
  font-size: 14px;
  display: block;
}

.error-input {
  border: 1px solid red !important;
}

.form-bg form small {
  font-size: 11px;
  line-height: 12px;
}

.form-bg form .form-checkbox {
  width: 1.7em;
  height: 1.7em;
  vertical-align: top;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #1fd0f9;
  border-radius: 0;
}

.form-bg form .form-checkbox:checked {
  color: #fff;
  background-color: #1fd0f9;
  border-color: #1fd0f9;
}

.form-bg form input:focus {
  box-shadow: none;
  background-color: #f6f6f6;
  border: 1px solid #4c9fb7;
}

.form-bg form button {
  outline: none;
  margin: 30px 10px 10px;
}

.details {
  margin: 3% 0;
}

.details h1 {
  color: #66b7c7;
  letter-spacing: -.4px;
  margin-bottom: 20px;
  font-family: Gelo-Regular, EuclidCircularB-Medium;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
}

.details h1 span {
  color: #f8e061;
}

.details h3 {
  color: #66b7c7;
  font-size: 16px;
  font-weight: 400;
}

.module {
  min-height: 200px;
  background: #66b7c7 url("background-hearts-desktop.288cd741.png") center / cover no-repeat;
  border-radius: 15px;
  margin: 80px;
  padding: 50px 40px;
}

.module .icon {
  text-align: center;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 5px solid #f8e061;
  border-radius: 50%;
  margin: -80px auto 10px;
  padding: 5px;
}

.module h1, .module2 h1 {
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 5px;
  font-family: Gelo-Regular, RobotoSlab-Bold, sans-serif;
  font-size: 35px;
}

.module h2, .module2 h2 {
  color: #fff;
  font-family: EuclidCircularA-Regular, sans-serif;
  font-size: 22px;
  line-height: 30px;
}

.module2 {
  min-height: 200px;
  background: #66b7c7 url("how-we-work-background1.b60636ff.png") 100% / contain no-repeat;
  border-radius: 15px;
  margin: 80px;
  padding: 50px 40px;
}

#sticky_isi {
  width: 100%;
  height: 184px;
  z-index: 1000;
  box-sizing: border-box;
  transition: height .4s linear;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 -1px 9px #0006;
}

.brand-container {
  width: 50%;
  margin: 0 auto;
}

.welcomenote {
  color: #a79c9a;
  padding: 0 100px;
  line-height: 30px;
}

.welcomemessage {
  color: #a79c9a;
}

.btn {
  text-align: center;
  color: #66b7c7;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  margin-top: 10px;
  padding: 10px 100px;
  font-family: EuclidCircularA-SemiBold, sans-serif;
  font-size: 20px;
  transition: all .5s;
}

.btn:hover {
  color: #fff;
  background-color: #0000;
  border: 2px solid #fff;
}

.btn i {
  padding-left: 10px;
}

.button {
  text-align: center;
  letter-spacing: .82px;
  border-radius: 30px;
  outline: none;
  margin: 10px 20px 0 0;
  padding: 16px 30px 14px;
  font-family: EuclidCircularA-SemiBold, sans-serif;
  font-size: 15px;
  line-height: 15px;
  transition: all .5s;
  display: inline-block;
}

.btn-primary {
  color: #66b7c7;
  background-color: #fff;
  border: 2px solid #66b7c7;
}

.btn-primary:hover {
  color: #fff;
  color: #fff;
  background-color: #6cacc0;
  border: 2px solid #6cacc0;
  text-decoration: none;
}

.btn-secondary {
  color: #fff;
  background-color: #6cacc0;
  border: 2px solid #6cacc0;
}

.btn-secondary:hover {
  color: #66b7c7;
  background-color: #fff;
  border: 2px solid #66b7c7;
  text-decoration: none;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #6cacc0;
  border: 2px solid #6cacc0;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #66b7c7;
  background-color: #fff;
  border: 2px solid #66b7c7;
}

.suc_bg {
  text-align: center;
  background-color: #00abc8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 30px 30px 20px;
}

.err-bg {
  text-align: center;
  background-color: #e02020;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 30px 30px 20px;
}

.err-bg i, .suc_bg i {
  color: #fff;
  margin-bottom: 20px;
  font-size: 90px;
}

.err-bg h1, .suc_bg h1 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.err-bg h2, .suc_bg h2 {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}

.err-bg p, .suc_bg p {
  color: #fff;
  margin-bottom: 5px;
  font-size: 13px;
}

.modal-content {
  border-radius: 10px;
}

.modal-footer {
  border-top: 0 solid #dee2e6;
  display: block;
}

.modal-footer .button {
  margin-top: 0;
}

.card-container {
  width: 80%;
  margin: 3% auto;
}

.eligibility {
  padding: 30px;
}

.eligibility p {
  color: #a79c9a;
}

.eligibility .listoption {
  margin: 15px 0;
}

.eligibility .form-check .form-check-input {
  width: 27px;
  height: 27px;
  border: 1px solid #ddd;
}

.eligibility .form-check label {
  padding-left: 10px;
  line-height: 35px;
}

.eligibility .form-check-input:checked {
  background-color: #66b7c7;
  background-image: none;
  border-color: #66b7c7;
}

.important {
  background-color: #fff;
  margin: 5% 0;
}

.headerimportant {
  color: #fff;
  background-color: #4c9fb7;
  padding: 8px 0;
  font-size: 20px;
}

.important-content {
  color: #857c7a;
  padding: 30px 0 0;
  font-family: EuclidCircularA-Regular, sans-serif;
}

.important-content .custom-teal {
  color: #429ca7;
}

.important-content h1 {
  color: #429ca7;
  font-family: EuclidCircularB-Medium, sans-serif;
  font-size: 20px;
}

.important-content ul {
  margin: 0;
  padding: 0 0 40px 20px;
}

.important-content ul li {
  color: #857c7a;
  margin: 15px 0;
  font-weight: normal;
  list-style: none;
  font-size: 18px !important;
}

.important-content ul li:not(.nav-item):before {
  content: "•";
  color: #f8e162;
  width: 1em;
  vertical-align: top;
  margin-top: -.3em;
  margin-left: -.7em;
  font-size: 30px;
  font-weight: 700;
  display: inline-block;
  position: absolute;
}

.important-content ul li b {
  color: #429ca7;
  font-family: EuclidCircularB-Medium, sans-serif;
}

.important-content ul li ul {
  margin: 0;
  padding: 0 0 0 30px;
}

.important-content ul li ul li {
  color: #857c7a;
  margin: 15px 0;
  font-weight: normal;
  list-style: none;
  font-size: 18px !important;
}

.important-content ul li ul li:not(.nav-item):before {
  content: "•";
  color: #f8e162;
  width: 1em;
  vertical-align: top;
  margin-top: -.3em;
  margin-left: -.7em;
  font-size: 30px;
  font-weight: 700;
  display: inline-block;
  position: absolute;
}

.important-content .custom-bullet ul {
  list-style: none !important;
}

.important-content .custom-bullet li {
  position: relative !important;
}

.important-content .custom-bullet li:before {
  height: .4em;
  border: 2px solid #f8e162;
  border-radius: 50%;
  top: 15px;
  content: " " !important;
  width: .4em !important;
  display: block !important;
  position: absolute !important;
}

.important-content .boxcontent {
  background-color: #f2fafc;
  margin: 10px 0 20px;
  padding: 20px;
}

.important-content p {
  font-size: 18px !important;
}

.important-content p a {
  color: #4c9fb7;
}

.important-content p a:hover {
  text-decoration: underline #4c9fb7;
}

.important-content .boxcontent ul {
  margin: 0;
  padding: 0 0 0 40px;
}

.important-content .boxcontent ul li {
  color: #429ca7;
  margin: 5px 0;
  font-family: EuclidCircularB-Medium, sans-serif;
  font-weight: bold;
  list-style: none;
  font-size: 19px !important;
}

.important-content .boxcontent ul li:not(.nav-item):before {
  content: "•";
  color: #f8e162;
  width: 1em;
  vertical-align: top;
  margin-top: -.4em;
  margin-left: -.7em;
  font-size: 30px;
  font-weight: 700;
  display: inline-block;
  position: absolute;
}

.backtotop {
  color: #fff;
  text-align: center;
  background-color: #4c9fb7;
  padding: 10px;
}

.backtotop p {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
}

.backtop-icon {
  width: 50px;
  height: 25px;
  color: #fff;
  background-color: #4c9fb7;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  display: inline-block;
}

.backtop-icon a:hover {
  cursor: pointer;
}

.backtop-icon i {
  vertical-align: bottom;
  color: #fff;
  padding: 3px;
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  transform: rotate(1deg);
}

footer {
  color: #444;
  background-color: #f2fafc;
  padding: 50px 0;
  font-size: 14px;
}

footer p {
  color: #857c7a;
  letter-spacing: .22px;
  font-size: 14px;
  line-height: 22px;
}

footer .acadia {
  margin-bottom: 10px;
}

footer ul {
  float: left;
  width: 100%;
  margin: 0 0 30px;
  padding: 0;
  display: inline-block;
}

footer ul li {
  float: left;
  padding: 5px 20px;
  list-style: none;
}

footer ul li:first-child {
  padding-left: 0;
}

footer ul li:after {
  border-right: 2px solid #857c7a;
}

footer ul li a {
  color: #4c9fb7;
  text-underline-offset: 1px;
  font-family: EuclidCircularB-Medium;
  font-size: 18px;
  line-height: 23px;
  text-decoration: underline;
}

footer ul li a:hover {
  color: #4c9fb7;
  text-decoration: underline #f8e162 !important;
}

@media (max-width: 1120px) {
  .bannerbg h1 {
    font-size: 65px;
  }

  .cardDesign {
    margin-right: 0;
  }

  .cardFooter h1 {
    font-size: 12px;
    line-height: 14px;
  }

  .module {
    margin: 30px 0;
  }

  .brand-container {
    width: 50%;
    margin: 0 auto;
  }

  .module2 {
    margin: 30px 0;
  }
}

@media (max-width: 768px) {
  .bannerbg h2 {
    margin-bottom: 0;
  }

  .bannerbg h1 {
    font-size: 45px;
    line-height: 45px;
  }

  .btn {
    padding: 10px 50px;
  }

  .cardFooter h1 {
    font-size: 12px;
    line-height: 14px;
  }

  .trademark-r {
    margin-left: -5px;
  }

  .cardDesign {
    margin-right: 0;
  }

  .module {
    margin: 30px 0;
  }

  .brand-container {
    width: 100%;
  }

  .module2 {
    margin: 30px 0;
  }

  .details h1 {
    font-size: 35px;
    line-height: 40px;
  }
}

/*# sourceMappingURL=enroll-brand.e0e43c2b.css.map */
